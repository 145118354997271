
import { defineComponent } from 'vue'
import { ElMessage } from 'element-plus';

import { queryServeCompany, configAdvanceServeCompany, getEmployeeList, editServerCompany } from '@/api/advance';
import { cloneDeep, unionBy } from 'lodash';
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        companyList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            dialogVisible: false,
            searchValue: '',
            // 公司列表
            companys: [] as any,
            // 已选择的公司
            selectedCompanys: [] as any,
            timer: null as any,
            errorList: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 300,
                total: 0
            },
            /**
             * 临时列表  所有的操作都基础临时列表
             * 包括分页时缓存操作过的数据  输入名称筛选时记录勾选的结果  删除时同时清空已选择列表和公司列表的选择状态
             * */
            tempList: [] as any,
            isCheckAll: false
        }
    },
    computed: {
        // isBeyond() {
        //     return (elName: string, index: number) => {
        //         // this.$nextTick(()=> {
        //             if (this.$refs[elName + index]) {
        //                 const el = this.$refs[elName + index] as any
        //                 console.log(777, el.clientWidth, el.scrollWidth);
        //                 return el.clientWidth >= el.scrollWidth
        //             }
        //         // })
        //     }
        // },
    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
            if (newval) {
                this.getCompanyList()
            } else {
                this.tempList = (this as any).companyList
                this.errorList = []
            }
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        },
        companyList: {
            handler(newval) {
                this.tempList = cloneDeep(newval)
            },
        },
        tempList: {
            handler(newval) {
                this.selectedCompanys = newval
            },
            immediate: true
        }
    },
    methods: {
        handleInput() {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.pageInfo.pageNum = 1
                this.getCompanyList()
            }, 300)
        },
        getCompanyList() {
            queryServeCompany({
                serveCompanyName: this.searchValue,
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize
            }, {
                loading: true
            }).then((res: any) => {
                this.pageInfo.total = res.totalCount
                if (this.pageInfo.pageNum === 1) {
                    this.companys = res.list
                } else {
                    this.companys = this.companys.concat(res.list)
                }
                this.companys.forEach((i: any) => {
                    if (!i.hasOwnProperty('selected')) {
                        i.selected = false
                    }
                    // 根据临时列表判断已选择了哪些并勾上
                    if (this.tempList?.length) {
                        i.selected = !!(this.tempList.find((k: any) => k.id === i.id))
                    }
                })
                this.isCheckAll = this.companys.every((i: any) => i.selected)
                console.log(12345, this.isCheckAll);
            })
        },
        // 保存配置企业
        handleSave() {
            configAdvanceServeCompany(this.selectedCompanys).then((res: any) => {
                if (res === true) {
                    ElMessage.success('保存成功')
                    this.$emit('success')
                    this.dialogVisible = false
                } else {
                    if (res?.length) {
                        this.errorList = res
                        res.forEach((i: any) => {
                            const exit = this.selectedCompanys.find((k: any) => k.id === i.id)
                            exit.isError = true
                            exit.showHint = true
                            exit.options = [
                                {
                                    contacts: exit.contacts,
                                    contactsId: exit.contactsId
                                }
                            ]
                        })
                    }
                }
            })
        },
        isBeyond(elName: string, index: number) {
            if (this.$refs[elName + index]) {
                const el = this.$refs[elName + index] as any
                return el.clientWidth >= el.scrollWidth
            }
            return false
        },
        handleClose() {
            this.dialogVisible = false
        },
        handleRemove(item: any) {
            item.selected = false
            // 删除时 删除临时列表的数据
            const index = this.tempList.findIndex((k: any) => k.id === item.id)
            if (index !== -1) {
                this.tempList.splice(index, 1)
            }
            // 公司列表对应的selected改成false
            const sltItem = this.companys.find(((i: any) => i.id === item.id)) as any;
            if (sltItem) {
                sltItem.selected = false
            }
        },
        remoteMethod(val: any, item: any) {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.getEmployeeList(val, item)
            }, 300)
        },
        getEmployeeList(val: string, item: any) {
            getEmployeeList({
                userName: val
            }).then((res: any) => {
                if (res?.length) {
                    res.forEach((i: any) => {
                        i.contacts = i.userName
                        i.contactsId = i.userId
                    })
                    item.options = res
                }
            })
        },
        handleChange(ev: any, item: any) {
            const cur = item.options.find((i: any) => i.contactsId === ev)
            item.contacts = cur.contacts
            editServerCompany(item).then(res => {
                if (res) {
                    item.showHint = false
                    const index = this.errorList.findIndex((i: any) => i.id === item.id)
                    if (index !== -1) {
                        this.errorList.splice(index, 1)
                    }
                }
            })
        },
        handleScroll(ev: any) {
            const el = ev.target
            if (el.clientHeight + el.scrollTop >= el.scrollHeight && (this.pageInfo.pageNum * this.pageInfo.pageSize < this.pageInfo.total)) {
                this.pageInfo.pageNum++
                this.getCompanyList()
            }
        },
        handleCheckboxChange(ev: any, item: any) {
            const index = this.tempList.findIndex((i: any) => i.id === item.id)
            if (ev) {
                if (index === -1) {
                    this.tempList.push(item)
                }
            } else {
                if (index !== -1) {
                    this.tempList.splice(index, 1)
                }
            }
            console.log(22222, this.tempList);
        },
        handleAllChange() {
            this.companys.forEach((i: any) => i.selected = this.isCheckAll)
            if (this.isCheckAll) {
                this.tempList = cloneDeep(this.companys)
            } else {
                this.tempList = []
            }
        }
    },
    directives: {
        isOver: {
            mounted(el, bonding) {
                // console.log(el);
                // console.log(1111, el.clientWidth, el.scrollWidth);
                setTimeout(() => {
                    if (el.clientWidth < el.scrollWidth) {
                        el.title = bonding.value
                    }
                }, 0);
            }
        }
    }
})
